// Generated by Framer (ced1a38)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["WtcWfIGHP", "Ijx17wWRW"];

const variantClassNames = {Ijx17wWRW: "framer-v-x95p7r", WtcWfIGHP: "framer-v-e3z4pt"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Phase 1 / Opacity": "Ijx17wWRW", "Phase 1": "WtcWfIGHP"};

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "WtcWfIGHP", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "WtcWfIGHP", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-tn3ZB", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-e3z4pt", className)} data-framer-name={"Phase 1"} layoutDependency={layoutDependency} layoutId={"WtcWfIGHP"} ref={ref} style={{opacity: 1, ...style}} transition={transition} variants={{Ijx17wWRW: {opacity: 0}}} {...addPropertyOverrides({Ijx17wWRW: {"data-framer-name": "Phase 1 / Opacity"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXIgVGlnaHQtNjAw", "--framer-font-family": "\"Inter Tight\", sans-serif", "--framer-font-size": "32px", "--framer-font-weight": "600", "--framer-line-height": "36px", "--framer-text-color": "var(--extracted-r6o4lv)"}}><motion.span style={{"--framer-text-color": "var(--extracted-1w3ko1f)"}}>Discover. </motion.span>The first part of my process is about learning all about your industry.</motion.p></React.Fragment>} className={"framer-n8sjea"} data-framer-name={"Discover. The first part of my process is about learning all about your industry."} fonts={["GF;Inter Tight-600"]} layoutDependency={layoutDependency} layoutId={"JKABOqGGx"} style={{"--extracted-1w3ko1f": "rgb(14, 14, 14)", "--extracted-r6o4lv": "rgb(134, 134, 139)", "--framer-paragraph-spacing": "0px"}} transformTemplate={transformTemplate} transition={transition} verticalAlignment={"top"} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-tn3ZB [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-tn3ZB .framer-nruq5w { display: block; }", ".framer-tn3ZB .framer-e3z4pt { height: 108px; overflow: hidden; position: relative; width: 479px; }", ".framer-tn3ZB .framer-n8sjea { flex: none; height: auto; left: 50%; position: absolute; top: 50%; white-space: pre-wrap; width: 479px; word-break: break-word; word-wrap: break-word; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 108
 * @framerIntrinsicWidth 479
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Ijx17wWRW":{"layout":["fixed","fixed"]}}}
 */
const FramerfvVfSrtKZ: React.ComponentType<Props> = withCSS(Component, css, "framer-tn3ZB") as typeof Component;
export default FramerfvVfSrtKZ;

FramerfvVfSrtKZ.displayName = "Phase";

FramerfvVfSrtKZ.defaultProps = {height: 108, width: 479};

addPropertyControls(FramerfvVfSrtKZ, {variant: {options: ["WtcWfIGHP", "Ijx17wWRW"], optionTitles: ["Phase 1", "Phase 1 / Opacity"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerfvVfSrtKZ, [{family: "Inter Tight", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/fvVfSrtKZ:default", url: "https://fonts.gstatic.com/s/intertight/v3/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mj0QiqXCRToK8EPg.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/intertight/v3/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mj0QiqXCRToK8EPg.ttf", weight: "600"}])